import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import theme from '../theme'

const CustomerStoryTile = ({
  company,
  text,
  image,
}: {
  company: string
  text: string
  image: IGatsbyImageData
}) => {
  return (
    <TileContainer href={`/story/${company.toLocaleLowerCase()}`}>
      <div className="text">
        <div>
          <b>{company + ' '}</b>
          {text}
        </div>
        <div className="read-story">
          <h4>Read story</h4>
          <span> →</span>
        </div>
      </div>
      <div className="img-wrapper">
        <GatsbyImage image={image} alt="Customer story" />
      </div>
    </TileContainer>
  )
}

export default CustomerStoryTile

const TileContainer = styled.a`
  display: flex;
  flex-direction: column-reverse;
  padding: 16px 0px;

  .text {
    font-size: 1.35rem;
    line-height: 2rem;
    margin-right: 12px;
    margin-top: 12px;
    max-width: 480px;

    .read-story {
      margin-top: 12px;

      h4 {
        display: inline;
        text-decoration: underline;
        text-decoration-color: #f3b3e0;
        text-underline-offset: 6px;
      }

      span {
        transition: all 0.15s ease-out;
        position: relative;
        top: 1px;
        left: -2px;
        font-weight: 500;
      }
    }
  }
  .img-wrapper {
    .gatsby-image-wrapper {
      width: 100%;
      max-width: 480px;
      height: auto;
      border-radius: ${theme.radii.large};
    }
  }

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    padding: 32px 40px;
    flex-direction: row;
    border-radius: ${theme.radii.large};
    transition: all 0.15s ease-out;
    max-width: 560px;

    .text {
      position: relative;
      font-size: 1.5rem;
      max-width: 280px;
      text-align: left;
      margin-right: 32px;
      margin-top: 12px;
    }
    .img-wrapper {
      .gatsby-image-wrapper {
        height: 240px;
        width: 220px;
        border-radius: 12px;
        filter: drop-shadow(0px 13.0802px 12.074px rgba(0, 0, 0, 0.05))
          drop-shadow(0px 17.1049px 38.2344px rgba(23, 23, 26, 0.05));
      }
    }

    &:hover {
      background: ${theme.colors.blue50};

      .read-story {
        h4 {
          text-decoration-color: ${theme.colors.dataPink};
        }
        span {
          left: 0px;
        }
      }
    }
  }
`
