import React from 'react'
import styled from 'styled-components'
import CustomerLogos from '../components/CustomerLogos'
import CustomerTestimonials from '../components/CustomerTestimonials'
import Layout from '../components/Layout'
import YoutubeVideo from '../components/YoutubeVideo'

const DemoRequestPage = () => {
  return (
    <Layout
      justifyCenter
      title="Watch a quick product overview of Swarmia"
      description="See Swarmia in action with our 12-minute product tour."
    >
      <Container>
        <div className="columns">
          <div className="col">
            <Intro />
          </div>
          <div className="col">
            <div
              style={{
                position: 'relative',
                width: '100%',
                paddingBottom: '56.25%',
                marginTop: '24px',
                height: '0',
              }}
            >
              <YoutubeVideo videoId="ZHcQO20bL4w" />
            </div>
          </div>
        </div>
        <CustomerLogos />
        <CustomerTestimonials />
      </Container>
    </Layout>
  )
}

const Intro = () => {
  return (
    <IntroStyled>
      <div>
        <h1>Take a quick product tour of Swarmia</h1>
        <div className="text-tight">
          <p>
            <b>
              See how Swarmia can help you improve engineering effectiveness in
              just 12 minutes.
            </b>
          </p>
          <p>
            Curious to see Swarmia in action but aren&#39;t ready for a{' '}
            <a href="/demo/">personal demo</a> or a{' '}
            <a href="https://app.swarmia.com/signup/">14-day trial</a> just yet?
            <p>
              Watch our product overview to understand the basics of Swarmia and
              learn how to use it to build a more effective engineering
              organization.
            </p>
          </p>
        </div>
        <br />
      </div>
    </IntroStyled>
  )
}

const IntroStyled = styled.div`
  text-align: left;
  .sub-heading {
    p {
      margin-top: 12px;
      margin-bottom: 35px;
      max-width: 400px;
    }
  }
`

const Container = styled.div`
  margin-top: 64px;
  .columns {
    display: flex;
    width: 100%;
    margin: auto;
    margin-bottom: 24px;
    max-width: 1280px;
  }
  .col {
    padding: 0 32px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  padding-bottom: 40px;

  & h3 {
    opacity: 0.5;
    font-weight: 500;
  }

  // Show only one column if mobile
  @media screen and (max-width: 981px) {
    .columns {
      flex-direction: column;
      .col {
        width: 100%;
      }
    }
  }
`

export default DemoRequestPage
